//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
import { user, form } from "@/hooks";
import { LoginDialog } from "@/components";
const hkUser = new user();
const hkForm = new form();
export default {
	name: "FormPage",
	props: {
		// nbr: {
		// 	type: String,
		// 	default: "",
		// },
	},
	components: {
		LoginDialog,
	},
	data() {
		return {
			form: {},
			valueData: [],
			result: {},
			isClose: false,
			formNbr: "",
			image: {},
			images: {},
			resultVal: {},
			list: [],
		};
	},
	// computed: {
	// 	...mapState({
	// 		userInfo(state) {
	// 			const userInfo = state.user.userInfo;
	// 			return userInfo;
	// 		},
	// 	}),
	// },
	created() {
		this.getInit();
	},
	mounted() {},
	methods: {
		async getInit() {
			await this.getMemInfo();
			await this.getList();
			await this.getFormInfo();
			await this.getFormValueInfo();
			if (hkUser.getUserNbr() && sessionStorage.getItem("form")) {
				this.cacheAddForm();
			}
		},
		async getMemInfo() {
			this.userInfo = this.$store.state.user.userInfo;
		},
		getList() {
			this.list = this.$store.state.page.pageInfo.List ?? [];
		},
		async getFormInfo() {
			this.form = this.list.find((x) => x.name == "HvForm");
			if (this.form) this.nbr = this.form.nbr;
		},
		async getFormValueInfo() {
			if (this.Common.isExist(this.form)) {
				if (!this.form.formInfo.isLimit) return false;
				const res = await hkForm.getFormValueInfo(this.nbr);
				if (res) {
					this.valueData = res.list;
					this.isClose = res.isClose;
					this.formNbr = res.formNbr;
				}
				console.log("valueData---");
				console.log(this.valueData);
			}
		},
		async imageAfterRead(file, key) {
			console.log(file, key);
			file.status = "uploading";
			file.message = "上傳中...";
			const upload = file.file;
			const res = await hkForm.uploadFile(upload, this.nbr, "image");
			if (res) {
				file.status = "done";
				file.message = "上傳成功";
				this.image[key] = res.fileName;
			}
		},
		async imagesAfterRead(file, key, images) {
			console.log(file, key);
			file.status = "uploading";
			file.message = "上傳中...";
			// console.log("images----");
			// console.log(images);
			if (!this.images[key]) this.images[key] = [];

			//找出已上傳過的文件
			const isFileName = images.some((e) => e.fileName);
			if (isFileName) {
				for (const img of images) {
					if (img.fileName) {
						const isExist = this.images[key].some((e) => e == img.fileName);
						if (!isExist) this.images[key].push(img.fileName);
					}
				}
			}

			if (file instanceof Array) {
				for (const item of file) {
					const upload = item.file;
					const res = await hkForm.uploadFile(upload, this.nbr, "image");
					if (res) {
						file.status = "done";
						file.message = "上傳成功";
						this.images[key].push(res.fileName);
					}
				}
			} else {
				const upload = file.file;
				const res = await hkForm.uploadFile(upload, this.nbr, "image");
				if (res) {
					file.status = "done";
					file.message = "上傳成功";
					if (this.images[key]) {
						this.images[key].push(res.fileName);
					} else {
						this.images[key] = res.fileName;
					}
				}
			}
		},
		async imageDelete(key) {
			if (this.image[key]) delete this.image[key];
		},
		async imagesDelete(key, detail) {
			if (this.images[key]) this.images[key].splice(detail.index, 1);
		},
		async formSubmit(val, { isMem }) {
			// console.log("val,formInfo-----");
			// console.log(val);
			const _val = JSON.parse(JSON.stringify(val));
			//修改時，替換圖片數據格式
			for (const key in val) {
				if (Array.isArray(val[key])) {
					if (val[key][0] && val[key][0].fileName) {
						const fileNames = val[key].map((item) => item.fileName);
						_val[key] = fileNames.toString();
					}
				}
			}
			//新增圖片時，替換圖片路徑
			for (const key in this.image) {
				const element = this.image[key];
				if (_val[key]) _val[key] = element;
			}

			for (const key in this.images) {
				const element = this.images[key].toString();
				if (_val[key]) _val[key] = element;
			}
			// console.log(_val);
			if (isMem) {
				const user = hkUser.getUserNbr();
				if (!user) {
					this.resultVal = _val;
					return (this.$refs.loginDialog.show = true);
				}
			}
			console.log("提交表單！！！");
			this.result = await hkForm.addForm(_val, this.nbr, this.formNbr);
			console.log("提交表單結果");
			console.log(this.result);
		},
		loginDialogConfirm(callback) {
			sessionStorage.setItem("form", JSON.stringify(this.resultVal));
			callback(this.resultVal);
		},
		async cacheAddForm() {
			this.result = await hkForm.addForm(JSON.parse(sessionStorage.getItem("form")), this.nbr, this.formNbr);
			sessionStorage.removeItem("form");
		},
	},
};
